import React, { useEffect, useState } from 'react';
import { getAccessibilityStatus, getBottom5Score, getScoreTrendMonthly, getTop5Score, getWebsites } from '../../modules/auth/redux/TestRunCRUD';
import { HistoricalStatsChartData } from '../../../_monic/partials/widgets/mixed/HistoricalStats';
import { PieChartData } from '../../../_monic/partials/widgets/mixed/PieChart';
import { Website } from '../../modules/auth/models/TestRunModel';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../setup';
import { Chart, ChartConfiguration } from 'chart.js'
import { decodeURISafe } from '../../modules/utils';
import { cleanString } from '../utils';
import { getDownloadXlsWebsiteReportUrl } from '../../modules/auth/redux/AuthCRUD';

const getScoreClass = (score?: number) => {
    if (!score) {
        return '';
    }
    if (score > 90) {
        return 'green';
    } else if (score > 40) {
        return 'yellow';
    }
    return 'red';
}

const formatDate = (year: number, month: number) => {
    // Get the month name based on the month number
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const formattedDate = `${monthNames[month - 1]} ${year}`;
  
    return formattedDate;
  }

const Dashboard: React.FC = () => {
    const [downloading, setDownloading] = useState(false)
    const [top5ScoreData, setTop5ScoreData] = useState<{ id: number, baseUrl: string, score: number }[]>();
    const [bottom5ScoreData, setBottom5ScoreData] = useState<{ id: number, baseUrl: string, score: number }[]>();
    const [scoreTrendMonthly, setScoreTrendMonthly] = useState<HistoricalStatsChartData>();
    const [accessibilityStatusData, setAccessibilityStatusData] = useState<{ websiteCnt: number, chartData: PieChartData }>();
    const [orgHeatmapData, setOrgHeatmapData] = useState<{ totalWebsites: number, heatmap: { departmentId: number, websites: Website[], totalScore: number, name: string, avgScore: number }[] }>();
    const [dataError, setDataError] = useState<DataError>();

    useEffect(() => {
        const element = document.getElementById('status_chart') as HTMLCanvasElement
        if (!element || !accessibilityStatusData || !accessibilityStatusData.chartData) {
            return;
        }

        const options: ChartConfiguration = {
            type: "pie",
            data: {
                labels: accessibilityStatusData?.chartData.xAxis,
                datasets: [
                    {
                        data: accessibilityStatusData?.chartData.yAxis || [],
                        backgroundColor: accessibilityStatusData?.chartData.colors
                    }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                animation: {
                    /* @ts-ignore */
                    animateScale: true,
                    animateRotate: true,
                },
                tooltips: {
                    enabled: true,
                },
            },

        }

        const ctx = element.getContext('2d')
        let myDoughnut: Chart | null
        if (ctx) {
            myDoughnut = new Chart(ctx, options);
        }
        return function cleanUp() {
            if (myDoughnut) {
                myDoughnut.destroy()
            }
        }
    }, [accessibilityStatusData])

    useEffect(() => {
        const element = document.getElementById('score_trend_chart') as HTMLCanvasElement
        if (!element || !scoreTrendMonthly || !scoreTrendMonthly) {
            return;
        }

        const options: ChartConfiguration = {
            type: "line",
            data: {
                labels: scoreTrendMonthly.xAxis,
                datasets: [{
                    label: 'Overall Accessibility Score Monthly Trend',
                    data: scoreTrendMonthly.yAxis,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1,
                    pointRadius: 5,
                    pointHitRadius: 10
                  }]
            },
            options: {
                plugins: {
                    legend: {
                        display: true,
                        onClick: () => {},
                    }
                },
                animation: {
                    /* @ts-ignore */
                    animateScale: true,
                    animateRotate: true,
                },
                tooltips: {
                    enabled: true,
                },
            },

        }

        const ctx = element.getContext('2d')
        let myDoughnut: Chart | null
        if (ctx) {
            myDoughnut = new Chart(ctx, options);
        }
        return function cleanUp() {
            if (myDoughnut) {
                myDoughnut.destroy()
            }
        }
    }, [scoreTrendMonthly])

    useEffect(() => {
        getTop5Score().then((result) => {
            if (!result.data) {
                return;
            }
            const res = result.data.map(x => ({ id: x.id, baseUrl: decodeURISafe(x.url), score: x.latestScore || 0 })).sort((a, b) => b.score - a.score);
            setTop5ScoreData(res);
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
        getBottom5Score().then((result) => {
            if (!result.data) {
                return;
            }
            const res = result.data.map(x => ({ id: x.id, baseUrl: decodeURISafe(x.url), score: x.latestScore || 0 })).sort((a, b) => a.score - b.score);
            setBottom5ScoreData(res);
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
        getAccessibilityStatus().then((result) => {
            if (!result.data) {
                return;
            }
            setAccessibilityStatusData({
                websiteCnt: result.data.compliant + result.data.nonCompliant + result.data.untested,
                chartData: {
                    xAxis: ['Compliant', 'Non Compliant', 'Not Tested'],
                    yAxis: [result.data.compliant, result.data.nonCompliant, result.data.untested],
                    colors: ['#05b455', '#f12f30', '#84838b'],
                    max: `Total: ${result.data.compliant + result.data.nonCompliant + result.data.untested}`
                }
            });
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
        getScoreTrendMonthly().then((result) => {
            if (!result.data) {
                return;
            }
            const res = result.data.map(x => ({ label: formatDate(x.year, x.month), value: Math.round(x.avgScore) }));
            console.log('res,', res);
            setScoreTrendMonthly({
                xAxis: res.map(x => x.label),
                yAxis: res.map(x => x.value),
                max: ''
            });
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
        getWebsites().then(result => {
            const websites = result.data;
            const websitesByOrg: Record<number, { departmentId: number, websites: Website[], totalScore: number, name: string, avgScore: number }> = {};
            for (const w of websites) {
                if (!w.latestScore) {
                    continue;
                }
                const x = websitesByOrg[w.departmentId] || { departmentId: w.departmentId, websites: [], totalScore: 0, name: w.department?.name };
                x.websites.push(w);
                x.totalScore += w.latestScore;
                websitesByOrg[w.departmentId] = x;
            }
            setOrgHeatmapData({
                totalWebsites: websites.length,
                heatmap: Object.values(websitesByOrg).map(w => ({
                    ...w,
                    avgScore: Math.round(w.totalScore / w.websites.length),
                })).sort((a, b) => b.avgScore - a.avgScore)
            });
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
    }, []);

    if (dataError) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div>
                        <br />
                        <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                            <div className='alert-text font-weight-bold'>{dataError.error}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const onClickDownload = () => {
        setDownloading(true);
        getDownloadXlsWebsiteReportUrl().then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            const fileName = response.headers["content-disposition"]?.split("filename=")[1];
            if (fileName) {
                link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setDownloading(false);
        }).catch(() => {
            setDownloading(false);
            console.error('Server Error! Cannot download the report');
        });
    }

    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    <li>Dashboard</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Dashboard</h1>
            </div>
            <div className="row mt-30">
                <div className="col-lg-6 col-md-6">
                    <div className="white-box">
                        <div className="accessibility-sites">
                            <div className="Top-5">
                                <h2>Accessibility</h2>
                                <p className="status-text">Status</p>
                            </div>
                            <div className="highest-box">
                                <h2>{orgHeatmapData?.totalWebsites}</h2>
                                <p className="status-text">Total <strong>Websites</strong></p>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="StatusBox">
                                <canvas id="status_chart" width="250" height="200"></canvas>
                            </div>
                        </div>
                        <div className="score-box stausLegends text-center">
                            <span className="dot green" style={{ marginLeft: 15, marginRight: 5 }}></span>
                            <span>Compliant</span>
                            <span className="dot red" style={{ marginLeft: 15, marginRight: 5 }}></span>
                            <span>Non Compliant</span>
                            <span className="dot gray" style={{ marginLeft: 15, marginRight: 5 }}></span>
                            <span>Not tested</span>
                        </div>
                    </div>

                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="white-box">
                        <div className="accessibility-sites">
                            <div className="Top-5">
                                <h2>Accessibility</h2>
                                <p className="status-text">Trends</p>
                            </div>
                        </div>
                        <div className="text-center">
                            <canvas id="score_trend_chart"></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div className="borderline"></div>
            <div className="row mt-20 equal-height">
                {[{title: 'Top 5', data: top5ScoreData, scoreTitlePrefix: 'Highest'}, {title: 'Bottom 5', data: bottom5ScoreData, scoreTitlePrefix: 'Lowest'}].map(x => 
                <div className="col-lg-6 col-md-6">
                <div className="white-box bottom-white-box ">
                    <div className="accessibility-sites">
                        <div className="Top-5">
                            <h2>{x.title}</h2>
                            <p className="status-text">Accessibility Sites</p>
                        </div>
                        <div className="highest-box">
                            <h2 className={getScoreClass(x.data && x.data.length > 0 ? x.data[0].score : 0)}>{x.data && x.data.length > 0 ? x.data[0].score : ''}</h2>
                            <p className="status-text">{x.scoreTitlePrefix} <strong>Score</strong></p>
                        </div>
                    </div>
                    <div className="progressive-box mtp-25">
                        {x.data?.map(x => <>
                            <Link to={`/v2/core/website/${x.id}/testruns`}>{x.baseUrl}</Link>
                            <span className={getScoreClass(x.score)} style={{ width: `${x.score}%` }} data-tooltip={`${x.score}%`} ></span>
                        </>)}
                    </div>
                    <div className="count-box">
                        <span>0</span>
                        <span>10</span>
                        <span>20</span>
                        <span>30</span>
                        <span>40</span>
                        <span>50</span>
                        <span>60</span>
                        <span>70</span>
                        <span>80</span>
                        <span>90</span>
                        <span>100</span>
                    </div>
                    <div className="score-box">
                        <span>Score</span>
                        <span className='reddot' style={{ background: '#32ba7c', marginRight: 5 }}></span>
                        <span>91 - 100</span>
                        <span className="yellowdot" style={{ marginLeft: 15, marginRight: 5 }}></span>
                        <span>41 - 90</span>
                        <span className="reddot" style={{ marginLeft: 15, marginRight: 5 }}></span>
                        <span>0 - 40</span>
                    </div>
                </div>
            </div>
                )}
            </div>
            <div className="row mtp-30">
                <div className="col-lg-12">
                    <div className="white-box bx-paddng accessibilityHeatmap">
                        <div className="accessibility-sites">
                            <div className="Top-5 d-flex">
                                <h2>Org Accessibility <span>Heat Map</span></h2>
                            </div>
                            {downloading ? (
                        <div className='btn btn-blue disabled'>
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Downloading... Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        </div>
                    ) : (
                            <a title='Download Test Report' target='_blank' onClick={onClickDownload}
                            className="btn btn-blue">Download Report <em className="fa fa-file-text-o" aria-hidden="true"></em></a>
                        )}
                        </div>

                        <div className="heatmap-legends-row">
                            <div className="heatmap-counts-box">
                                <div className="heatmap-counts">
                                    <div className="h2">{orgHeatmapData ? Object.keys(orgHeatmapData?.heatmap).length : 0}</div>
                                    <span className="heatmap-counts-text">Organizations</span>
                                </div>
                                <div className="heatmap-counts">
                                    <div className="h2">{orgHeatmapData?.totalWebsites}</div>
                                    <span className="heatmap-counts-text">Websites</span>
                                </div>
                            </div>
                                <div className="score-box text-right">
                                    <span>Score Range</span>
                                    <span className="reddot" style={{ marginLeft: 15, marginRight: 5 }}></span>
                                    <span>0 to 40</span>
                                    <span className="yellowdot" style={{ marginLeft: 15, marginRight: 5 }}></span>
                                    <span>41 to 90</span>
                                    <span className="bluedot" style={{ marginLeft: 15, marginRight: 5 }}></span>
                                    <span>91 to 100</span>
                                </div>
                            </div>

                        <div className="progressive-box mtp-25">
                            {orgHeatmapData?.heatmap?.map(x => <>
                                <p className="org-name">{cleanString(x.name)}</p>
                                <div className="progBarOut">
                                    <div className="bar-bg">
                                        <span className={getScoreClass(x.avgScore)} style={{ width: `${x.avgScore}%` }} />
                                    </div>
                                    <span className="scoreData">
                                        <strong>{x.avgScore}</strong><br />
                                        <span className="score_txt">score</span>
                                    </span>
                                    <div className="tittleBx">
                                        <p><strong className="titleCount">{x.avgScore}</strong> <strong>Avg Score</strong></p>
                                        <p>Website Owned: <strong>{x.websites.length}</strong></p>
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Dashboard }
